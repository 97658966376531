/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Checkbox, Icon, Popconfirm, Modal, Button, InputNumber, Row, Col, DatePicker, Card, Input } from 'antd';
import "../../App.css";
import ResponsiveTable from "../../components/ResponsiveTable";
import PageToolbar from "../../components/PageToolbar";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import { escapeRegExp } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const topHeight = 340; // px
const rowHeight = 30;  // px

class ScheduledServices extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            modalCreateEdit: null,
            modalCreateEditBackup: null,
        }

        this.doOnResize = null;

        this.id = "COMPANY_ORDER_SUPPLIER_TABLE";
        this.selector_pos = TableIDs.genericSelectorPos;
        this.selector_sup = TableIDs.genericSelectorExternalOrderSuppliers;

        this.api = {
            get: "get_company_order_supplier_table",
            delete: "delete_company_order_supplier_table",
            create_edit: "create_edit_company_order_supplier_table",
            get_params: "get_company_order_supplier_params",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.selector_pos] === undefined) props.data[this.selector_pos] = { ...props.data.genericSelector };
        if (props.data[this.selector_sup] === undefined) props.data[this.selector_sup] = { ...props.data.genericSelector };

        this.toolbarActions = [
            {
                buttonType: "add",
                buttonFunction: this.addRow
            }, {
                buttonType: "refresh",
                buttonFunction: this.refreshData
            }
        ];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14324);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);


        let request = {
            // ui
            page: 1,
            recordsPerPage: /*5,*/numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: /*5,*/numOfRowsByHeight,
            _words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: 1,
            sortColumn: 1,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    addRow = () => {
        let newRecord = {}
        this.props.data[this.id].headers.forEach((x) => { newRecord = { ...newRecord, [x]: x === 'mId' ? '0' : '' } });
        this.setState({ modalCreateEdit: newRecord, modalCreateEditBackup: newRecord });
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getSuppParams = (mSuppId) => {
        let sData = { mSuppId }
        if (this.state.modalCreateEdit) sData = { ...this.state.modalCreateEdit, mSuppId }
        this.sendAPI(this.api.get_params, mSuppId, (ob) => {
            if (ob.data) {
                let x = ob.data.split("\f");
                sData = {
                    ...sData,
                    mPrm1Name: x[0],
                    mPrm2Name: x[1],
                    mPrm3Name: x[2],
                    mPrm4Name: x[3],
                    mPrm5Name: x[4],
                    mPrm6Name: x[5],
                    mPrm7Name: x[6],
                    mPrm8Name: x[7],
                    mPrm9Name: x[8],
                    mPrm10Name: x[9],
                    mNextInterval: x[10],
                }
                this.setState({ modalCreateEdit: sData })
            }
        })
    }

    modalCreateEdit = () => {
        let sData = this.state.modalCreateEdit ? this.state.modalCreateEdit : {}
        let isEdit = parseInt(sData.mId);
        let mobile = window.innerWidth <= 600;
        let update = (field, value) => { this.setState({ modalCreateEdit: { ...sData, [field]: value } }) }

        let titleBar = (<div style={{ padding: 4 }}>
            <PageToolbar title={this.getText(14339)} actionButtons={[]} isModal={true} />
        </div>);

        let formItem = (label, content) => {
            return (<div style={{ paddingTop: 10 }}>
                <div style={{ display: 'inline-block', width: '40%' }}>{this.getText(label)}</div>
                <div style={{ display: 'inline-block', width: '60%' }}>{content}</div>
            </div>)
        }

        let getInput = (label, field) => {
            return formItem(label, (<Input value={sData[field]} onChange={(e) => { update(field, e.target.value) }} />))
        }

        let posView = isEdit ?
            (sData.mPosId ? sData.mPosId + " - " + sData.mPosName : '')
            : (<div style={{ paddingLeft: 10 }}>
                {/* <GenericSelector {...this.props} id={this.selector_pos} api={'list_pos'} value={sData.mPosId} onChange={(e) => { update('mPosId', e) }} /> */}
                <GenericSelectorPosStorages {...this.props} value={sData.mPosId} onChange={(e) => { update('mPosId', e) }} />
            </div>)

        let supView = isEdit ?
            (sData.mSuppId ? sData.mSuppId + " - " + sData.mSuppName : '')
            : (<div style={{ paddingLeft: 10 }}>
                <GenericSelector {...this.props} id={this.selector_sup} api={'external_orders_supplier_list'} value={sData.mSuppId} onChange={(e) => {
                    this.getSuppParams(e)
                }} />
            </div>)

        let nextRunView = (<DatePicker
            showTime format={'DD/MM/YYYY HH:mm:ss'}
            value={sData.mNextRun ? moment(sData.mNextRun, "YYYY-MM-DD HH:mm:ss") : null}
            onChange={(value) => { update('mNextRun', value ? value.format("YYYY-MM-DD HH:mm:ss") : '') }}
        />)

        let nextIntervalUserEdited = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '530')
        let nextIntervalView = (<InputNumber min={60} step={10} value={sData.mNextInterval} onChange={(e) => { update('mNextInterval', e) }} readOnly={!nextIntervalUserEdited} />)
        let isActiveView = (<Checkbox checked={sData.mIsActive == '1'} onChange={(e) => { update('mIsActive', e.target.checked ? '1' : '0') }} />)

        let params = [];
        for (let x = 1; x <= 10; x++) {
            if (parseInt(sData['mPrm' + x + 'Name']))
                params.push({ label: parseInt(sData['mPrm' + x + 'Name']), field: 'mPrm' + x });
        }

        let close = () => { this.setState({ modalCreateEdit: null, modalCreateEditBackup: null }) }
        let clear = () => { this.setState({ modalCreateEdit: this.state.modalCreateEditBackup }) }
        let save = () => {
            this.props.dataActions.genericDataSaveEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, this.state.modalCreateEdit);
            setTimeout(close, 1000);
        }

        return (<Modal visible={this.state.modalCreateEdit !== null} onCancel={close} width={'95%'} title={titleBar} footer={[
            <Button key="back" onClick={close}>{this.getText(14340)}</Button>,
            <Button key="clear" onClick={clear}>{this.getText(14341)}</Button>,
            <Button key="create" type="primary" onClick={save}>{this.getText(14342)}</Button>,
        ]}>
            <Row>
                <Col span={mobile ? 24 : 12} style={{ padding: 20 }}>
                    <Card style={{ backgroundColor: '#dddddd' }} title={this.getText(14345)}>
                        {formItem(14343, posView)}
                        {formItem(14344, supView)}
                        {formItem(14330, nextRunView)}
                        {formItem(14331, nextIntervalView)}
                        {formItem(14332, isActiveView)}
                        {getInput(14347, 'mToken')}
                    </Card>
                </Col>
                <Col span={mobile ? 24 : 12} style={{ padding: 20 }}>
                    <Card style={{ backgroundColor: '#dddddd' }} title={this.getText(14346)}>
                        {params.map(x => getInput(x.label, x.field))}
                    </Card>
                </Col>
            </Row>

        </Modal>)
    }


    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let val = (text) => text;
        let cbVal = (text) => (<Checkbox checked={text == '1'} />);

        let editButtons = (text, record) => {
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                    this.setState({ modalCreateEdit: record, modalCreateEditBackup: record });
                    // this.props.dataActions.genericDataStartEditing(this.id_sql, record.key, record.index);
                }} />
                <Popconfirm title={this.getText(14336)} okText={this.getText(14337)} cancelText={this.getText(14338)} onConfirm={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                </Popconfirm>
            </span>);
        }

        let columns = [
            { title: this.getText(14326), dataIndex: "mPosId", key: "mPosId", width: '10%', render: val },
            { title: this.getText(14327), dataIndex: "mPosName", key: "mPosName", width: '20%', render: val },
            { title: this.getText(14328), dataIndex: "mSuppId", key: "mSuppId", width: '10%', render: val },
            { title: this.getText(14329), dataIndex: "mSuppName", key: "mSuppName", width: '20%', render: val },
            { title: this.getText(14330), dataIndex: "mNextRun", key: "mNextRun", width: '20%', render: val },
            { title: this.getText(14331), dataIndex: "mNextInterval", key: "mNextInterval", width: '10%', render: val },
            { title: this.getText(14332), dataIndex: "mIsActive", key: "mIsActive", width: '5%', render: cbVal },
            { title: this.getText(14333), width: '5%', render: editButtons },
        ];


        return (<div style={divStyle}>
            <ResponsiveTable
                title={this.getText(14325)}
                actionButtons={this.toolbarActions}
                idTable={this.props.data[this.id]}
                showsearch={(val) => {
                    this.props.dataActions.genericDataSetFilter(
                        this.id, this.props.user.companyCode, this.props.user.token,
                        {
                            _words: escapeRegExp(val),
                            //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"), 
                            page: 1,
                            _page_num: 1
                        },
                        this.api.get)
                }}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                percentageToMobile={[false, 70, 30]}
                columns={columns}
                onChange={this.handleTableChange}
            />
            {this.modalCreateEdit()}
        </div>)
    }

}
export default ScheduledServices;